import { useEffect, useRef } from "react";

export const useInterval = (callback, delay = 1000) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    const intervalId = setInterval(tick, delay);
    return () => clearInterval(intervalId);
  }, [delay]);
};
