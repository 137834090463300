import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { unmountComponentAtNode } from "react-dom";
import { useInterval, useSession } from "../hooks";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

export const useAutoLogoutTimer = (secondsForAutoLogout = 15 * 60, secondsForWarning = 90) => {
  const [secondsLeftToTimeout, setSecondsLeftToTimeout] = useState(secondsForAutoLogout);
  const [timeoutFired, setTimeoutFired] = useState(false);
  const [warningTimeoutFired, setWarningTimeoutFired] = useState(false);
  const { endSession } = useSession();
  const checkTimerInterval = 1000;

  const resetTimer = () => {
    setSecondsLeftToTimeout(secondsForAutoLogout);
    setTimeoutFired(false);
    setWarningTimeoutFired(false);
  };

  const addListeners = () => {
    window.addEventListener("click", resetTimer);
    window.addEventListener("keydown", resetTimer);
    window.addEventListener("keyup", resetTimer);
    window.addEventListener("keypress", resetTimer);
  };

  const removeListeners = () => {
    window.removeEventListener("click", resetTimer);
    window.removeEventListener("keydown", resetTimer);
    window.removeEventListener("keyup", resetTimer);
    window.removeEventListener("keypress", resetTimer);
  };

  const checkTimer = () => {
    if(timeoutFired) {
      return;
    }

    const shouldTimeoutFire = secondsLeftToTimeout <= 0;
    const shouldWarnTimeoutFire = secondsLeftToTimeout <= secondsForWarning;


    if (shouldTimeoutFire) {
      killSession();
    } else if (shouldWarnTimeoutFire) {
      setWarningTimeoutFired(true);
      showAutoLogoutWarning();
    }

  };

  useInterval(() => {
    setSecondsLeftToTimeout(s => s - 1);
    checkTimer();
  }, checkTimerInterval);

  useEffect(() => {
    addListeners();
    resetTimer();

    return () => {
      removeListeners();
    };
  }, []);

  // super hack to manually call the close() code for react-confirm-alert
  // taken from: https://github.com/GA-MO/react-confirm-alert/blob/master/src/index.js
  const onCloseHack = () => {
    // removeBodyClass()
    document.body.classList.remove('react-confirm-alert-body-element');

    // remoteElementReconfirm()
    var target = document.getElementById('react-confirm-alert');
    (0, unmountComponentAtNode)(target);
    target.parentNode.removeChild(target);

    // removeSVGBlurReconfirm()
    var svg = document.getElementById('react-confirm-alert-firm-svg');
    svg.parentNode.removeChild(svg);
    document.body.children[0].classList.remove('react-confirm-alert-blur');
  };

  const killSession = () => {
    onCloseHack();

    // end the session
    endSession();
  };

  const handleLogOff = () => {
    endSession();
  };

  const showAutoLogoutWarning = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="auto-logout-warning-container">
            <h1>Are You Still There?</h1>
            <p>
              You will be logged off in the next {secondsLeftToTimeout} seconds
              <br />
              due to inactivity.
            </p>
            <button
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                //onClose();
                //handleLogOff();
                killSession();
              }}
            >
              Log Off
            </button>
            <button
              onClick={() => {
                resetTimer();
                onClose();
              }}
            >
              Continue Working
            </button>
          </div>
        );
      }
    });
  };

  return {
    secondsLeftToTimeout,
    timeoutFired,
    warningTimeoutFired
  };
};
