/**
 * Matomo Analytics
 */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState(window._paq);
  const [previousUrl, setPreviousUrl] = useState("");
  const user = useSelector(state => state.user);
  const location = useLocation();

  useEffect(() => {
    const { pathname } = location;
    trackPageView(pathname);
  }, [location]);


  // According to the following, setUserId must be called before each tracking request:
  // https://developer.matomo.org/guides/tracking-javascript-guide#user-id
  const setUserId = () => {
    const { userName } = user;

    if(userName) {
      analytics.push(["setUserId", userName]);
    }
  };

  const resetUserId = () => {
    analytics.push(["resetUserId"]);
  };

  const trackEvent = (category, action, name = null, value = null) => {
    setUserId();
    analytics.push(["trackEvent", category, action, name, value]);
  };

  const trackPageView = (url = window.location.pathname, title = window.document.title) => {
    setUserId();
    analytics.push(["setReferrerUrl", previousUrl]);
    analytics.push(["setCustomUrl", url]);
    analytics.push(["setDocumentTitle", title]);

    // remove all previously assigned custom variables
    analytics.push(["deleteCustomVariables", "page"]);
    analytics.push(["setGenerationTimeMs", 0]);

    analytics.push(["trackPageView"]);

    /*
     * // make Matomo aware of newly added content
     *let content = document.getElementById("app");
     *analytics.push(["MediaAnalytics::scanForMedia", content]);
     *analytics.push(["FormAnalytics::scanForForms", content]);
     *analytics.push(["trackContentImpressionsWithinNode", content]);
     *analytics.push(["enableLinkTracking"]);
     */

    setPreviousUrl(url);
  };

  return { trackEvent, trackPageView, resetUserId };
};
