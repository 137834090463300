import React, { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { useAnalytics } from "../hooks";
import { SET_USER_SESSION, UNSET_USER_SESSION, SESSION_STORAGE_KEY } from "../core/User";
import { loadState, saveState, removeState } from "../storage";
import { AbilityContext, updateAbility } from "../ability";
import version from "../version.json";

export const useSession = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSessionReady, setIsSessionReady] = useState(false);
  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();
  const user = useSelector(state => state.user);
  const history = useHistory();
  const ability = useContext(AbilityContext);

  //console.log('inside of useSession, ability:', ability);

  const initSession = () => {
    //console.log("inside of initSession");
    // Attempt to load a user session from storage.
    // This allows a user to stay logged in across browser refreshes.
    const savedState = loadState(SESSION_STORAGE_KEY);

    // If savedState exists then load it into Redux.
    if (savedState) {
      const { userName } = savedState || "";
      dispatch({ type: SET_USER_SESSION, value: savedState });
      trackEvent("Application", "Session initialized from saved state", userName);

      // update Ability
      updateAbility(ability, savedState);
    } else {
      trackEvent("Application", "Session initialized");
    }
  };

  // Login
  const setSessionUser = user => {
    const { userName } = user;

    // Send analytics
    trackEvent("Users", "Login succeeded", userName);

    // save session to storage
    saveState(SESSION_STORAGE_KEY, user);

    // save user session to redux
    dispatch({ type: SET_USER_SESSION, value: user });

    // update Ability
    updateAbility(ability, user);
  };

  // Logout
  const unsetSessionUser = () => {
    const { userName } = user;

    // remove session from storage
    removeState(SESSION_STORAGE_KEY);

    // remove user session from redux
    dispatch({ type: UNSET_USER_SESSION });

    // Send analytics
    trackEvent("Users", "Logout succeeded", userName);

    // update Ability
    updateAbility(ability, null);
  };

  // Handles user updates (like after user has signed the Data Use Certification)
  // Similar to setSessionUser without the concept of "login"
  const updateSessionUser = user => {
    // save session to storage
    saveState(SESSION_STORAGE_KEY, user);

    // save user session to redux
    dispatch({ type: SET_USER_SESSION, value: user });

    // update Ability
    updateAbility(ability, user);
  };

  const startSession = () => {};

  const endSession = () => {
    const { userName } = user;

    // Logout user
    unsetSessionUser();

    trackEvent("Application", `End session`, userName);

    // update Ability
    updateAbility(ability, null);

    // force app to reload back to the index page
    window.location.assign("/");
  };

  return {
    initSession,
    setSessionUser,
    unsetSessionUser,
    updateSessionUser,
    startSession,
    endSession,
    isSessionReady,
    setIsSessionReady
  };
};
/*
 **        OrienAvatarAPI.request(options, dispatch)
 **            .then(() => {
 **                Analytics.trackEvent("Users", "Logout succeeded", state().user.userName);
 **            })
 **            .catch(() => {
 **                Analytics.trackEvent("Users", "Logout failed", state().user.userName);
 **            })
 **            .then(() => {
 **                sessionStorage.removeItem(constants.SESSION_STORAGE_KEY);
 **                dispatch({
 **                    type: actionTypes.USER_NOT_AUTHENTICATED,
 **                    value: null
 **                });
 **                Analytics.resetUserId();
 **                window.location.assign("/");
 **            });
 */
